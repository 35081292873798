<template>
    <div class="footer-margin">
        <div class="directory">
            <Breadcrumb />
            <Search :showCalendar="true" :title="'Kulturkalender'" @dateTimeChanged="dateTimeSearchParamsHaveChanged" @reload="searchParamsHaveChanged"/>

            <div class="container-fluid" v-if="dataSet.total>0">
                <div class="row mt30">
                    <div class="col-xl-8 offset-xl-1 col-lg-10 col-md-12">
                        <div class="row directory--element"
                        v-for="group in categoryContentList"
                        :key="group.category">
                        <div class="col-12">
                            <h3 class="title">{{group.category}}</h3>
                        </div>

                        <div class="col-lg-4 col-md-6 directory--element__single"
                        v-for="content in group.contents"
                        :key="content.id">
                        <CardEvent :content="content" :date="dataSet.query.selectedDate" :marginRight="'0px'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="no-calendar-results">              
      <div class="container-fluid blank">
        <div class="blank-section">
            <img src="/assets/blank-image.png" alt="Blank">
            <h4 class="mt20">Keine Termine für diese Auswahl gefunden.</h4>
        </div>
    </div>
</div>
</div>

</div>

</template>

<script>
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import Search from '@/components/Search.vue';
    import CardEvent from '@/components/cards/CardEvent.vue';

    import { showLoader, hideLoader, sortByProperty, getFieldValues } from '@/utils/helpers';
    import { getContentsOfType } from '@/api/content';


    export default {
        name: 'CultureCalendar',
        components: {
            Breadcrumb,
            Search,
            CardEvent
        },
        data() {
            return {
                loader: null,
                contents: null,
                dataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 50,
                    keyword: '',
                    type: 'view',
                    selectedDate: new Date(),
                    selectedAppId: this.$appId,
                    view_status: 1,
                    selectedContentTypeId: 2,
                    sort: {
                      prop: '',
                      order: 'asc'
                  },
              },
          },
      }
  },
  computed: {
    categoryContentList() {
        if (!this.contents || !Array.isArray(this.contents)) {
            return [];
        }

        const resultSet = [];
        let currentCategory = null;
        let currentResultSet = null;

        for (const content of this.contents) {
            // Skip items without category
            if (!content.category) continue;

            // If category changes or first iteration
            if (content.category !== currentCategory) {
                // Push previous result set if exists
                if (currentResultSet) {
                    resultSet.push(currentResultSet);
                }

                // Create new result set
                currentCategory = content.category;
                currentResultSet = {
                    category: content.category,
                    contents: []
                };
            }

            // Add content to current category group
            currentResultSet.contents.push(content);
        }

        // Push the last result set
        if (currentResultSet) {
            resultSet.push(currentResultSet);
        }

        return resultSet;
    }
},
created() {
    this.getContents();
},
methods: {
    showLoader,
    hideLoader,
    sortByProperty,
    async getContents() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.dataSet.query;
        const { data, meta } = await getContentsOfType(this.$offerId,this.dataSet.query);
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.enhanceContentAttribsWithCategory();
        this.contents = this.sortContentsByCategories();
        this.loader = this.hideLoader(this.loader);
    },
    enhanceContentAttribsWithCategory(){
        for(var k = 0; k < this.dataSet.data.length; k++){
            var content = this.dataSet.data[k];
            /*get the categories from this content*/
            var cats = getFieldValues(content,'kultur_kategorien');
            /*check if there is a value in the cats*/
            if(cats!=null && cats.length>0){
                /*is cats an array?*/
                if(Array.isArray(cats)){
                    content.category = cats[0];
                }
                else{
                    content.category = cats;
                }
            }
        }
    },
    sortContentsByCategories(){
        return sortByProperty(this.dataSet.data, 'category', 'de');
    },
    dateTimeSearchParamsHaveChanged(params){
        this.dataSet.query.selectedDate = params.selectedDate;
        this.dataSet.query.selectedTimeFrame = params.selectedTimeFrame;
        this.getContents();
    },
    searchParamsHaveChanged(query){
        this.dataSet.query.keyword = [];
        Object.keys(query).forEach((k) => {
            this.dataSet.query.keyword.push(query[k]);
        });

        this.getContents();
    }
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .no-calendar-results{

        .container-fluid{
            max-width: 250px;
            margin: 0 auto;
            text-align: center;
        }

        .blank{
            display: flex;
            align-items: center;
            height: 60vh;
        }
    }

    .directory {
        background: #fff;

        h1 {
            font-size: 24px;
            margin: 30px 0;
        }

        &--element{
            position: relative;
            overflow: hidden;
            padding: 15px 0;

            .title {
                position: relative;
                background: #fff;
                display: inline-block;
                padding-right: 20px;

                &::after {
                    content: '';
                    position: absolute;
                    left: 100%;
                    bottom: 6px;
                    width: 1500px;
                    height: 3px;
                    background: $secondary;
                    z-index: 1;
                }
            }

            &__single {
                position: relative;
                margin-top: 30px;

                @media (max-width: 786px) {
                    margin-top: 20px;
                }
            }
            margin-bottom: 50px;
        }

        &.footer-margin {
            @media (max-width: 786px) {
                margin-bottom: 0 !important;
            }
        }
    }
</style>
