<template>
    <div class="card-wrapper">
        <a :href="collectionUrl" class="card card-collections" :class="[isCurrentlyActiveCard, isSmall? 'small':'']" :style="{width: width, marginRight: marginRight, marginBottom: marginBottom}" @click="navigateToView" v-if="collection!=null && numberOfContents > 0">
            
            <div class="card-img" :class="checkSafari?'safari':''" :style="getBackgroundImage()">
                <div class="news-category" v-if="audioType.includes('Tour mit Audio')">
                    <span>Audio</span>
                </div>
                
            </div>
            
            <div class="card-detail">
                <div class="row mb10">
                    <h6 class="col-6 collection-name">{{ collection.title }}</h6>
                </div>
                <ul class="card-detail__features">
                    <li v-if="distance!==null">
                        <img src="assets/icons/width.svg" alt="icon" >
                        <span>{{ distance }} km</span>
                    </li>
                    <li v-if="footPath!==null">
                        <img src="assets/icons/walk.svg" height="18px;" alt="icon" >
                        <span>{{ footPath }} min</span>
                    </li>
                    <li v-if="bikePath!==null">
                        <img src="assets/icons/bicycle.svg" alt="icon" >
                        <span>{{ bikePath }} min</span>
                    </li>
                    <li>
                        <button v-if="creditsText"
                        alt="Infos zu den Erstellern" 
                        class="btn btn-link btn-favorite btn-info" 
                        @click.stop.prevent="showCreditsDialog = true">
                        <i class="material-icons" aria-hidden="true">info</i>
                    </button>
                </li>
            </ul>
            
        </div>
    </a>
    <div v-if="showCreditsDialog && creditsText" class="credits-popup">
        <div class="credits-popup__content">
            <button class="close-button" @click.stop="showCreditsDialog = false">
                <i class="material-icons">close</i>
            </button>
            <h3>Credits</h3>
            <div class="credits-text" v-html="creditsText">
            </div>
        </div>
    </div>
</div>

</template>

<script>
import { parseTravelTime, getPlaceholderImage, json_decode, getFieldValues } from '@/utils/helpers';

export default {
    name: "CardKultour",
    props: {
        collection: {
            type: Object,
            default: null,
            required: true
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '100%'
        },
        marginRight: {
            type: String,
            default: '15px'
        },
        marginBottom: {
            type: String,
            default: '30px'
        },
        disableLink: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showCreditsDialog: false,
            showAddToCollectionDialog: false,
        };
    },
    computed: {
        imageHeight: function() {
            return this.isSmall ? '105px' : '210px';
        },
        checkSafari(){
            if(this.isSafari&&this.hasPath("footPath")){
                return true;
            }
            return false;
        },
        isLoggedIn(){
            if(this.$store.getters.getToken){
                return true;
            }
            return false;
        },
        isInCollectionClass(){
            if(this.isLoggedIn && this.isContentInCollection()){
                return 'active';
            }
            return '';
        },
        numberOfContents(){
            if(this.collection!=null){
                let stations = getFieldValues(this.collection, 'beziehungen_zu_anderen_inhalten');
                if(stations !== null){
                    return stations.length;
                }
            }
            return 0;
        },
        creditsText() {
            return getFieldValues(this.collection, 'credits');
        },
        collectionImage: function() {
            if(this.collection!=null){
                var logo = getFieldValues(this.collection,'teaser-bild');
                if(logo!=null){
                    logo = json_decode(logo);
                    if(typeof logo === "object"){
                        return this.$backendUrl+logo.path;
                    }
                    else { 
                        logo = logo.charAt(0)!="/"?"/"+logo:logo;   
                        return this.$backendUrl+logo;
                    }
                }
            }
            return this.getPlaceholderImage();
        },
        linkToMerklisteOptions(){
            return "Optionen zum Merken für: "+this.collection.title;
        },
        kultourType(){
            if(this.collection!==null){
                return getFieldValues(this.collection,'typ');
            }
            return null;
        },
        audioType(){
            if(this.collection!==null){
                return getFieldValues(this.collection,'audio');
            }
            return null;
        },
        metaObject(){
            if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'meta')){
                return JSON.parse(this.collection.meta);
            }
            return null;
        },
        bikePath: function() {
            if(this.collection!==null) {
                return getFieldValues(this.collection,'dauer_mit_dem_rad_in_minuten');
            }
            return null;
        },
        collections(){
            return this.$store.getters.getCollections;
        },
        footPath: function() {
            if(this.collection!==null) {
                return getFieldValues(this.collection,'dauer_zu_fu_in_minuten');
            }
            return null;
        },
        distance(){
            if(this.collection!==null){
                return getFieldValues(this.collection,'weglnge_in_km');
            }
            return null;
        },
        isCurrentlyActiveClass: function() {
            if (this.isCurrentlyActive){
                return 'highlight';
            }
            return '';
        },
        isCurrentlyActiveCard(){
            if(this.collection.highlight == true){
                return "highlight";
            }
            return 'not-highlighted';
        },
        isSafari(){
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if(iOS){
                return true;
            }
            else{
                return false;
            }
        },
        collectionUrl(){
            var props = "";
            if(this.collection.content_type_name=='kultour'){
                props = this.$router.resolve({ 
                    name: 'Kulturspur',
                    params: { id: this.collection.id },
                });
            }
            else{
                props = this.$router.resolve({ 
                    name: 'Collection',
                    params: { id: this.collection.id },
                });
            }
            
            return props.href;
        },
    },
    methods: {
        parseTravelTime,
        getPlaceholderImage,
        json_decode,
        triggerAddToCollectionDialog(event){
            event.preventDefault();
            this.showAddToCollectionDialog=true;
        },
        emitReloadCollection(collection_id){
            this.$emit('reloadCollection',collection_id);
        },
        isContentInCollection(){
            if(this.collections!=null){
                for (var prop in this.collections) {
                    var collectionGroup = this.collections[prop];
                    for(var i=0; i<collectionGroup.length; i++){
                        var currentCollection = collectionGroup[i];
                        if(currentCollection!=null && Object.prototype.hasOwnProperty.call(currentCollection,'contents') && currentCollection.contents.length>0){
                            for(var j=0; j<currentCollection.contents.length; j++){
                                if(currentCollection.contents[j].id == this.collection.id){
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
        hasPath(path){
            if(path!=null && Object.prototype.hasOwnProperty.call(path,'paths') && path.paths.length>0){
                return true;
            }
            return false;
        },
        getBackgroundImage(){
            return 'background-image: url("' + this.collectionImage + '"); max-height:'+ this.imageHeight;
        },
        navigateToView(event){
            if(this.disableLink){
                this.$emit('highlight',{sender:'collection-card', collection: this.collection});
                event.preventDefault()
            }
        },
        showCredits(event) {
            event.preventDefault();
            this.$emit('show-credits', this.collection);
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card.small .card-detail__features li{
    display: block;
    
    span{
        display: block;
        margin: 0 auto;
        text-align:center;
    }
    img{
        height: 18px;
        display: block;
        margin: 0 auto;
    }
}

.card-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 364px;
}

.btn-favorite.btn-info {

    position: relative;
    padding: 6px!important;
    border-color: rgba($color: $primary, $alpha: 0.5)!important;

    i{
        border: 0.5px solid rgba($color: $primary, $alpha: 0.5)!important;
        color: rgba($color: $primary, $alpha: 0.5)!important;
        padding: 5px!important;
        &:hover{
            border: 0.5px solid transparent!important;
            background-color: rgba(227, 0, 89, 0.85)!important;
            box-shadow: none!important;
            color: #fff!important;
        }
    }
    
    
    
    &:active, &:focus{
        background-color: transparent!important;
        box-shadow: none!important;
    }
}

.card {
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    border: none;
    width: 364px;
    margin-right: 15px;
    box-shadow: 0px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
    &:hover,
    &:focus,
    &:active {
        color: $primary;
        box-shadow: 0px 5px 15px 0px rgba($color: $primary, $alpha: 0.35);
    }
    
    &.highlight{
        border: 3px solid $secondary;
    }
    
    
    .collection-name {
        font-size: 14px!important;
    }
    
    .card-img {
        position: relative;
        overflow: hidden;
        min-height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        
        @media (max-width: 600px){ 
            min-height: 80px;
        }
        
        .news-category {
            position: absolute;
            top: 20px;
            right: 0px;
            background: rgba($color: #E30059, $alpha: 0.85);
            padding: 10px 20px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            
            span {
                margin-bottom: 0;
                text-transform: uppercase;
                font-size: 14px;
                color: #fff;
                line-height: 1.25;
                font-family: 'elliot-bold', sans-serif;
                font-weight: 700;
            }
        }
        
        &.safari{
            @media (max-width: 600px) and (max-height: 600px){ 
                display:none;
            }
        }
        
        img {
            width: 100%;
            overflow: hidden;
        }
    }
    
    .card-detail {
        padding: 20px;
        
        @media (max-width: 600px){ 
            padding: 10px;
        }
        
        .title {
            font-size: 14px;
            margin-bottom: 10px;
        }
        
        &__features {
            display: flex;
            
            li {
                flex: 1;
                display: inline-flex;
                vertical-align: middle;
                align-items: center;
                
                i, img {
                    margin-right: 10px;
                }
                
                img {
                    display: inline-block;
                    max-height: 24px;
                }
                
                &.highlight {
                    color: $success !important;
                    
                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.credits-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
    &__content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        max-width: 500px;
        width: 90%;
        position: relative;
    }
    
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background: none;
        cursor: pointer;
    }
}

</style>
