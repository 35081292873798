<template>
    <div>
        <div class="directory">
            <Breadcrumb />
            <Search :title="'Verzeichnis A-Z'" @reload="reload"/>

            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt30">
                        <div class="row directory--element"
                        v-for="item in alphabeticalInstitutionList"
                        :key="item.letter">
                        <div class="col-12">
                            <h2 class="title">{{item.letter}}</h2><div class="bar"></div>
                        </div>

                        <div class="col-lg-4 col-md-6 directory--element__single" v-for="institution in item.institutions" :key="institution.id">
                            <CardInstitution 
                            :content="institution" 
                            :isSmall="false"
                            :marginBottom="'0px'"
                            :marginRight="'0px'"
                            v-if="institution!=null"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-margin">
    </div>

</div>
</template>

<script>
    import Search from '@/components/Search.vue';
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import CardInstitution from '@/components/cards/CardInstitution.vue';

    import { search } from '@/api/content';
    import { showLoader, hideLoader, sortByProperty } from '@/utils/helpers';

    export default {
        name: 'Verzeichnis',
        components: {
            Breadcrumb,
            Search,
            CardInstitution
        },
        data() {
            return {
                loader: null,
                institutionDataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 1000,
                    type: 'view',
                    view_status: 1,
                    selectedAppId: this.$appId,
                    selectedContentTypeId: this.$institutionId,
                    keyword: '',
                    sort: {
                      prop: 'title',
                      order: 'ascending'
                  },
              },
          },
      }
  },
  computed: {
    institutions(){
        if(this.institutionDataSet.data != null && this.institutionDataSet.data.length>0){
            /*hard-coded Langcode*/
            return sortByProperty(this.institutionDataSet.data, 'title', 'de');
        }
        return null;
    },
    alphabeticalInstitutionList(){
        if(this.institutions != null){

            /*we need to order the institutions into an array of objects by the first letter*/
            /*init*/
            var resultSet = [];
            var singleResultSet = {};
            var letter = null;

            for(var i = 0; i < this.institutions.length; i++){
                var institution = this.institutions[i];
                /*let's check if the current letter is the same as the first letter from the title of the institution*/
                if(institution.title.substring(0,1).toUpperCase() != letter){
                    /*check if the current letter is null. If that is not the case, we'll push the singleResultSet onto the resultSet*/
                    if(letter!=null){
                        resultSet.push(singleResultSet);
                    }

                    /*set the letter for the comparison*/
                    letter = institution.title.substring(0,1).toUpperCase();

                    singleResultSet = { 
                        letter: institution.title.substring(0,1).toUpperCase(),
                        institutions: [],
                    };
                }

                /*add the current institution to the singleResultSet*/
                singleResultSet.institutions.push(institution);

            }
            /*at the end of the for-loop there is still one last singleResultSet that is not part of the overall result set.*/
            resultSet.push(singleResultSet);
            return resultSet;
        }
        return null;

    }
},
created() {
    this.getInstitutionList();
},
methods: {
    showLoader,
    hideLoader,
    sortByProperty,
    async getInstitutionList() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.institutionDataSet.query;
        const { data, meta } = await search(this.institutionDataSet.query);
        this.institutionDataSet.data=data;
        data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });

        this.institutionDataSet.meta = meta;
        this.institutionDataSet.total = meta.total;
        this.loader = this.hideLoader(this.loader);
    },
    reload(query){

        this.institutionDataSet.query.keyword = [];

        Object.keys(query).forEach((k) => {
            if(Array.isArray(query[k])){
                Object.keys(query[k]).forEach((l) => {
                    if(query[k][l]!=null){
                        this.institutionDataSet.query.keyword.push(query[k][l]);
                    }
                });
            }
            else{
                if(query[k]!=null){
                    this.institutionDataSet.query.keyword.push(query[k]);
                }
            }
        });

        this.getInstitutionList();
    }
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .directory {
        position: relative;
        background: #fff;

        .container{

            @media (max-width: 991px){
                max-width: 100%;
                padding: 0px 40px;
            }

            @media (max-width: 490px){
                max-width: 100%;
                padding: 0px 20px;
            }

        }

        &--element{
            .title {
                font-size: 24px;
                background: #fff;
                display: inline-block;
                padding-right: 20px;
            }

            .bar{
                height: 3px;
                width: calc(100% - 50px);
                background-color: #E30059;
                display: inline-block;
                margin-bottom: 7px;
            }

            &__single {
                margin-top: 30px;

                @media (max-width: 490px){
                    margin-top: 15px;
                }

            }
            margin-bottom: 30px;
        }

        &.footer-margin {
            @media (max-width: 786px) {
                margin-bottom: 0 !important;
            }
        }
    }
</style>
