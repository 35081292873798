<template>
    <div class="profile footer-margin" v-if="content!==null">
        <Breadcrumb />
        
        <div class="container">
            
            <div class="institution-header">
                <div class="profile-image">
                    <div v-if="titelBild!=null">
                        <div tabindex="-1" class="full-width profile-image-placeholder" :class="titelBild.class" :style="getBackgroundImage(titelBild.image)"></div>
                        <span class="copyright" v-if="titelBild.copyright!=null">&copy; {{titelBild.copyright}}</span>
                    </div>
                    <button title="Merken" class="btn btn-link btn-favorite special" :class="isInCollectionClass" @click="showAddToCollectionDialog=true">
                        <i class="material-icons" aria-hidden="true">anchor</i>
                    </button>
                </div>
                <div class="profile-heading d-flex justify-content-between">
                    <div class="profile-heading__title">
                        <span>Geschichte</span>
                        <h4>{{ title }}</h4>
                    </div>
                    
                    <share-button :is-device-mobile="isItMobile"/>
                    
                </div>
            </div>
            
            <div class="row time-and-place">
                
                <div class="col-12 time-and-place-col" v-if="addressObj != null && hasDisplayableAdressData">
                    <div :class="['dropdown', isActiveLocation ? 'is-active': '']">
                        <button class="dropdown-head no-btn" @click="toggleMap">
                            <div class="inline-icon-text">
                                <i class="material-icons icon" aria-hidden="true">location_on</i>
                                <h5>{{adresse}}</h5>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div :class="['dropdown', isActiveLocation ? 'is-active': '', !hasDisplayableAdressData?'mt10':'']" v-if="addressObj">
                <div class="dropdown-list map-dropdown" style="height: 500px;">
                    <the-map :address-obj="addressObj"></the-map>
                </div>
            </div>
            
            <div class="koultour-text mt30" v-if="audioWalkUrl !== null">
                <AudioCard :audioWalk="audioWalk"/>
            </div>
            
            <div class="card profile-text mt30 mb30">
                <div class="intro" v-if="introduction!==null" v-html="introduction"/>
                
                <div class="beschreibung mt10" v-if="textContent!==null" v-html="textContent"/>
                
                <GalleryComponent class="mt10" v-if="sliderImages!=null" :slider-images="sliderImages" />
                
                <sub-content class="mt10" v-for="subContent in subContents" :content="subContent"/>
                
            </div>
                        
            <attraktion-collection :collectionIds="collections" class="mb30"/>
            
            <!-- <CardConnection :address="adresse" /> -->
        </div>
        
        <AddContentToCollection :content="content" v-if="showAddToCollectionDialog" @closeModal="showAddToCollectionDialog=false"></AddContentToCollection>
        
    </div>
</template>

<script>
import Resource from '@/api/resource';
import { getInstitutionCollections } from '@/api/institution';
import { getFieldValues, showLoader, hideLoader, json_decode, getPlaceholderImage, isset } from '@/utils/helpers';
import { isMobile } from 'mobile-device-detect';
import GalleryComponent from '@/components/details/gallery.vue';
const contentResource = new Resource('contents');

export default {
    name: 'Geschichte',
    components: {
        GalleryComponent,
        TheMap: () =>import('@/components/map/single.vue'),
        AudioCard: () =>import('@/components/cards/CardAudio.vue'),
        AttraktionCollection: () =>import('@/components/kultour/collections.vue'),
        Story: () =>import('@/components/kultour/story.vue'),
        SubContent: () =>import('@/components/kultour/subContent.vue'),
        CardSimple: () =>import('@/components/cards/CardSimple.vue'),
        Breadcrumb: () =>import('@/components/controls/Breadcrumb.vue'),
        ShareButton: () =>import('@/components/controls/ShareButton.vue'),
        CardConnection: () =>import('@/components/cards/CardConnection.vue'),
        AddContentToCollection: () =>import('@/components/modal/addContentToCollection/dialog.vue'),
    },
    data() {
        return {
            loader: null,
            content: null,
            sliderImages: null,
            lightBoxIndex: 0,
            lightBoxVisible: false,
            showAddToCollectionDialog: false,
            isItMobile: isMobile ? true : false,
            collectionDataSet: {
                total: 0,
                data: null,
                meta:null,
                query: {
                    page:1,
                    limit: 6,
                    selectedAppId: this.$appId,
                    keyword: '',
                    selectedContentTypeId: null,
                    view_status: 1,
                    sort: {
                        prop: '',
                        order: 'asc'
                    },
                },
            },
            isActiveLocation: false,
            isActiveTime: false,
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        perPage(){
            return this.isItMobile ? 1 : 3;
        },
        titelBild(){
            var image = getFieldValues(this.content,'titelbild');
            if(image != null){
                var image = json_decode(image);
                if(typeof image === "object"){
                    return {image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description};
                }
                else {
                    return {image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null};  
                }
            }
            /*we'll need an alternative if there is no image*/
            return this.getPlaceholderImage(this.category);
        },
        textContent(){
            return getFieldValues(this.content,'body');
        },
        teaserImages(){
            var pictures = getFieldValues(this.content,'teaser-bilder');
            var picture = null;
            var decodedPictures = [];
            if(pictures != null){
                if(Array.isArray(pictures)){
                    for(var i=0; i<pictures.length; i++){
                        picture = json_decode(pictures[i]);
                        decodedPictures.push(picture);
                    }
                    return decodedPictures;
                }
                else{
                    picture = json_decode(pictures)
                    decodedPictures.push(picture);
                    return decodedPictures;
                }
            }
            return null;
        },
        isLoggedIn(){
            if(this.$store.getters.getToken){
                return true;
            }
            return false;
        },
        userCollections(){
            return this.$store.getters.getCollections;
        },
        collections(){
            if(this.content !== null){
                return getFieldValues(this.content,'kultouren');
            }
            return null;
        },
        isInCollectionClass(){
            if(this.isLoggedIn && this.isContentInCollection()){
                return 'active';
            }
            return '';
        },
        introduction(){
            if(this.content !== null){
                return getFieldValues(this.content,'einleitung');
            }
            return '';
        },
        subContents(){
            if(this.content !== null){
                var gna = getFieldValues(this.content,'zwischeninhalt');
                if(!Array.isArray(gna)){
                    return [gna];
                }
                return gna;
            }
            return '';
        },
        audioWalk(){
            if(this.content !== null) {
                return getFieldValues(this.content, 'audiowalk');
            }
            return null;
        },
        audioWalkUrl(){
            if(this.audioWalk !== null) {
                return getFieldValues(this.audioWalk, 'audio-datei');
            }
            return null;
        },
        sehenswrdigkeiten(){
            if(this.content !== null){
                var gna = getFieldValues(this.content,'sehenswrdigkeit');  
                if(!Array.isArray(gna)){
                    return [gna];
                }
                return gna;
            }
            return null;
        },
        kultouren(){
            if(this.content !== null){
                return getFieldValues(this.content,'kultouren');
            }
            return null;
        },
        aContent(){
            if(this.institution != null && Object.prototype.hasOwnProperty.call(this.institution,'content') && this.institution.content!=null){
                return this.institution.content;
            }
            return null;
        },
        title(){
            if(this.content!=null){
                return this.content.title;
            }
            return "";
        },
        addressObj(){
            if(this.content!==null){
                return getFieldValues(this.content,'adresse');
            }
            return '';
        },
        adresse(){
            if(this.addressObj!=null && isset(this.addressObj.street) && isset(this.addressObj.zipcode) && isset(this.addressObj.city)){
                return this.addressObj.street+', '+this.addressObj.zipcode+' '+this.addressObj.city;
            }
            return '';
        },
        hasDisplayableAdressData(){
            if(this.addressObj!=null && isset(this.addressObj.street) && isset(this.addressObj.zipcode) && isset(this.addressObj.city)){
                return true;
            }
            return false;
        },
        tags(){
            var tags = getFieldValues(this.aContent,'kultur_-_tags');
            if(tags != null){
                if(Array.isArray(tags)){
                    return tags;
                }
                else{
                    return [tags];
                }
            }
            return null;
        },
        description(){
            var desc = getFieldValues(this.aContent,'body');
            return desc!=null?desc:'';
        },
        logo(){
            var logo = getFieldValues(this.aContent,'logo');
            return json_decode(logo);
        },
        currentPath(){
            return window.location.href;
        },
        shareViaEmail(){
            return "mailto:?body="+this.currentPath;
        },
    },
    created() {
        this.getContent();
        this.isActiveLocation=true;
        if(isset(this.addressObj) && addressObj.hasOwnProperty()){
        }
    },
    methods: {
        getPlaceholderImage,
        getFieldValues,
        showLoader, 
        hideLoader,
        json_decode,
        getSlides(){
            var images = [];
            if(this.logo != null){
                if(typeof this.logo === "object"){
                    images.push({image:this.$backendUrl+this.logo.path,class:'logo', copyright:this.logo.copyright,description:this.logo.description});
                } 
                else {
                    var logo = this.logo.charAt(0)!="/"?"/"+this.logo:this.logo;
                    images.push({image:this.$backendUrl+logo,class:'logo', copyright:null,description:null});
                }
            }
            
            if(this.teaserImages != null){
                for(var i = 0; i < this.teaserImages.length; i++){
                    var image = json_decode(this.teaserImages[i]);
                    if(typeof image === "object"){
                        images.push({image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description});
                    }
                    else {
                        images.push({image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null});  
                    }
                }
            }
            
            return images;
        },
        getBackgroundImage(image){
            console.log(image);
            return 'background-image: url("' + image + '")';
        },
        getSubcontentSlides(id){
            return this.getFieldValues(subContents[id],'bilder');
        },
        showLightbox(index){
            this.lightBoxVisible = true;
            this.lightBoxIndex = index;
        },
        hideLightbox(){
            this.lightBoxVisible = false;
        },
        onShareError(err) {
            alert(err);
            console.log(err);
        },
        onShareSuccess(err) {
            console.log(err);
        },
        getContent() {
            this.loader = this.showLoader(this.loader);
            contentResource.get(this.id)
            .then(response => {
                this.content = response.data;
                this.sliderImages = this.getSlides();
            })
            .finally(() => {
                this.loader = this.hideLoader(this.loader);
            });
        },
        isContentInCollection(){
            if(this.userCollections!=null){
                for (var prop in this.userCollections) {
                    var collectionGroup = this.userCollections[prop];
                    for(var i=0; i<collectionGroup.length; i++){
                        var currentCollection = collectionGroup[i];
                        if(currentCollection!=null && Object.prototype.hasOwnProperty.call(currentCollection,'contents') && currentCollection.contents.length>0){
                            for(var j=0; j<currentCollection.contents.length; j++){
                                if(currentCollection.contents[j].id == this.content.id){
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
        toggleUhrzeit(){
            this.isActiveTime = !this.isActiveTime;
            /*hide map if activeTime is shown*/
            if(this.isActiveTime){
                this.isActiveLocation = false;
            }
        },
        toggleMap(){
            this.isActiveLocation = !this.isActiveLocation;
            /*hide map if activeTime is shown*/
            if(this.isActiveLocation){
                this.isActiveTime = false;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card{
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-left-radius: 30px;
    border: none;
    padding: 2rem;
    box-shadow: -5px 5px 15px 0px rgba(0, 24, 62, 0.2);
}

.profile {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;
    
    &-text{
        
        .intro{
            font-family: 'elliot-bold', sans-serif;
        }
        
        .heading{
            margin: 20px 0px 5px;
            font-size: 14px;
            font-weight: 700;
            font-family: 'elliot-bold', sans-serif;
        }
        
        .info-text{
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
            font-style: italic;
        }
        
    }
    
    .container {
        max-width: 705px;
    }
    
    .center-md{
        @media (max-width: 991px) {
            text-align:center!important;
        }
    }
    
    .btn.btn-favorite.special .material-icons{
        color: #E30059;
        border: 2px solid #E30059;
    }
    
    .btn.btn-favorite.special .material-icons:hover{
        color: #fff;
        background-color: #E30059;
    }
    
    .btn.btn-round.special{
        color: #00183E;
        border: 2px solid #00183E;
    }
    
    .btn.btn-round.special:hover,.btn.btn-round.special:active,.btn.btn-round.special:focus{
        color: #fff;
        background-color: #00183E;
    }
    
    .display-appointments{
        margin-left: 40px;
    }
    
    .institution-header{
        @media (max-width: 767px) {
            padding: 0 20px;
        }
    }
    
    .time-and-place{
        margin-top: 20px;
        
        @media (max-width: 991px) {
            margin-top: 10px;
        }
        
        @media (max-width: 501px) {
            margin-top: 0px!important;
        }
        
        .time-and-place-col{
            
            @media (max-width: 767px) {
                width: 100%;
                text-align: center!important;
                margin-top: 10px;
            }
            
            i{
                vertical-align: bottom;
                margin-right: 10px;
            }
            
            h5 {
                font-size: 16px;
            }
            
        }
        
    }
    
    .map-dropdown{
        
        @media (max-width: 560px) {
            height: 400px!important;
        }
        
        @media (max-width: 480px) {
            height: 350px!important;
        } 
    }
    
    &-image {
        position: relative;
        z-index: 1;
        
        &-placeholder {
            height: 371px;
            background-repeat: no-repeat;  
            background-position: center center; 
            background-size: cover;
            &.logo{
                background-size: contain!important;
            }
            
            @media (max-width: 560px) {
                height: 260px;
            }
            
            @media (max-width: 480px) {
                height: 220px;
            }
        }
        
        @media (max-width: 767px) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    
    
    
    &-heading {
        position: relative;
        z-index: 2;
        top: 0;
        padding: 10px 20px;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        border-radius: 5px;
        border-bottom-left-radius: 30px;
        
        @media (max-width: 767px) {
            margin-left: -15px;
            margin-right: -15px;
        }
        
        &__title{
            width: calc(100vw - 70px);
            margin-bottom: 10px;
            
            
            h4{
                margin-top: 5px;
            }
        }
    }
    
    @media (max-width: 767px) {
        padding-top: 70px;
    }
    
    @media (max-width: 350px) {
        padding-top: 60px;
    }
}
</style>
